// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './DimensionResult.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Callout, LineDiagram } from 'ui/basic';

// UTILS
import { setDecimalSeparator } from 'utils/userpreference';
import { isValid } from 'utils/numbers';
import { useTranslate } from 'utils/translator';
import { markdown } from 'utils/textTools';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  colors: {
    self: 'yellow',
    primary: 'blue',
  },
};

// COMPONENT: DimensionResult
const DimensionResult = (props) => {
  // PROPS
  const {
    dimension = {},
    segmentsCount,
    renderMin,
    showPeerResults = true,
    showPeerClassification = true,
    showDistribution = false,
    clusterDistribution,
    includeOverallBeforePeers = true,
    peerGroups,
    colors,
    range,
    showDotLegend = false,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const hasRange = (dim) => isValid(dim.minResult) && isValid(dim.maxResult) && dim.minResult !== dim.maxResult;
  const getColor = (id) => colors?.[id] ?? Config.colors[id] ?? Config.colors.primary;

  const peers = dimension.peerResults
  ?.filter((el) => !peerGroups || peerGroups.includes(el.id));

  const overallDistribution = dimension.peerResults
  ?.filter((el) => el.weight !== 0)
  .sort((el) => (el.id === 'self' ? 1 : -1))
  .map((el) => ({
    id: el.id,
    value: el.result,
    style: getColor(el.id),
  }));

  const [ hoveredDot, setHoveredDot ] = useState();

  // RENDER: RangeLabels
  const renderRangeLabels = (dim) => {
    if (!hasRange(dim)) {
      return null;
    }

    return (
      <div className={styles.lowestHighestValues}>
        <div>
          <span>
            { translate('lowest_lbl') }
            :
          </span>
          <span>{ setDecimalSeparator(dim.minResult) }</span>
        </div>
        <div>
          <span>
            { translate('highest_lbl') }
            :
          </span>
          <span>{ setDecimalSeparator(dim.maxResult) }</span>
        </div>
      </div>
    );
  };

  // RENDER: DimensionResult
  return (
    <div className={classNames(styles.dimensionResult)}>
      <span className='bluTypeXs'>{ dimension.name || dimension.id }</span>
      <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
        { markdown(dimension.description) || '-' }
      </div>

      { /* overall result */ }
      { includeOverallBeforePeers && (
        <div className='marginTopXs'>
          <span className='bluTypeXxs'>
            { translate('big5_report_dimension_result') }
          </span>

          <div className={styles.diagramBlock}>
            <div>{ setDecimalSeparator(dimension.result) }</div>
            <div className={styles.diagram}>
              <LineDiagram
                score={dimension.result}
                range={hasRange(dimension)
                  ? [ dimension.minResult, dimension.maxResult ]
                  : undefined}
                renderMin={renderMin}
                renderMax={segmentsCount}
                distribution={hasRange(dimension) && showDistribution
                  ? overallDistribution
                  : undefined}
                hoveredDot={hoveredDot}
                style={{
                  score: getColor('overall'),
                  range: getColor('overall'),
                }}
                showKde={range?.showDensity}
                kdeBandwidth={range?.bandwidth}
              />
            </div>
          </div>

          { showDotLegend && (
            <div className={styles.legend}>
              { peers.map((peer) => (
                <div
                  key={peer.id}
                  className={styles.lItem}
                  onMouseEnter={() => setHoveredDot(peer.id)}
                  onMouseLeave={() => setHoveredDot(undefined)}
                >
                  <div className={classNames(styles.circle, styles[getColor(peer.id)])} />
                  <span className={styles.text}>
                    { peer.name || translate(`peer_360_peer_perspectives_${peer.id}`) }
                    { ': ' }
                    <span className={styles.blue}>{ setDecimalSeparator(peer.result) }</span>
                  </span>
                </div>
              )) }
            </div>
          ) }

          { !showDotLegend && renderRangeLabels(dimension) }
        </div>
      ) }

      { /* peer results */ }
      { showPeerResults && Boolean(peers?.length) && (
        <div className='marginTopXs'>
          <span className='bluTypeXxs'>
            { translate('peer_360_peer_perspectives') }
          </span>

          { peers.map((peerResult) => (
            <div className={styles.peerResult} key={peerResult.id}>
              <div className={styles.textContent}>
                <span>{ peerResult.name || translate(`peer_360_peer_perspectives_${peerResult.id}`) }</span>
                <span>{ setDecimalSeparator(peerResult.result) }</span>
              </div>

              <div className='marginTopXs'>
                <LineDiagram
                  score={peerResult.result}
                  range={hasRange(peerResult)
                    ? [ peerResult.minResult, peerResult.maxResult ]
                    : undefined}
                  renderMin={renderMin}
                  renderMax={segmentsCount}
                  distribution={hasRange(peerResult) && showDistribution
                    ? peerResult.distribution?.map((el) => ({ value: el }))
                    : undefined}
                  style={{
                    score: getColor(peerResult.id),
                    range: getColor(peerResult.id),
                  }}
                  clusterEnabled={clusterDistribution?.enabled}
                  clusterDistance={clusterDistribution?.distance}
                  showKde={range?.showDensity}
                  kdeBandwidth={range?.bandwidth}
                />
              </div>

              { renderRangeLabels(peerResult) }
            </div>
          )) }
        </div>
      ) }

      { /* peer classification */ }
      { (showPeerClassification && dimension.peerClassification && dimension.peerClassification !== 'average') && (
        <div className='marginTopL'>
          <Callout triangleOnTop={false}>
            <>
              <span className='bluTypeLabelL'>
                { translate(`peer_360_classification_title_${dimension.peerClassification}`) }
              </span>
              <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                { translate(`peer_360_classification_copy_${dimension.peerClassification}`) }
              </div>
            </>
          </Callout>
        </div>
      ) }
    </div>
  );
};

export default DimensionResult;
