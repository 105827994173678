// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './QuestionDragDropBestLeast.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';
import { ReactComponent as Close } from './icons/icn_close.svg';
import { ReactComponent as MultipleTargetsGraphic } from './icons/multipleTargetsGraphic.svg';
import { ReactComponent as SingleTargetGraphic } from './icons/singleTargetGraphic.svg';
import { ReactComponent as CursorUp } from 'assets/icons/icn_cursor_up.svg';
import { ReactComponent as CursorDown } from 'assets/icons/icn_cursor_down.svg';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


// OTHER COMPONENTS
import { Button, Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

const DUMMY_HINT = 'Use {{key arrow up / key arrow down}} on your keyboard to switch between questions.';


// COMPONENT: QuestionDragDropBestLeast
const QuestionDragDropBestLeast = (props) => {
  // PROPS
  const {
    question = {},
    selectedValue,
    singleTarget = false,
    dispatchLocal = () => {},
    onAnswer = () => {},
  } = props;

  // Before proceeding, translate selectedValue in-place.
  // See https://blueexcellence.atlassian.net/browse/BQDQ-873
  if (selectedValue) {
    const translateSelectedValue = (key = '') => {
      if (!selectedValue[key]) {
        return;
      }

      const option = question?.answerOptions?.find(({ option }) => option === selectedValue[key]?.id || option === selectedValue[key]?.option);
      if (option) {
        selectedValue[key].label = option.text;
        selectedValue[key].hints = option.description;
      }
    };

    translateSelectedValue('best');
    translateSelectedValue('least');
  }

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // OPTIONS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ options, setOptions ] = useState([]);

  const [ best, setBest ] = useState();
  const [ least, setLeast ] = useState();

  useEffect(() => {
    if (options.length || (!options.length && best && least)) {
      return;
    }

    if (!selectedValue) {
      setOptions(question.answerOptions?.map((answerOption) => ({
        id: answerOption.option,
        label: answerOption.text,
        hints: answerOption.description,
      })) || []);

      return;
    }

    const { best: selectedBest, least: selectedLeast } = selectedValue;

    // single target question
    if (question.representation === 'select-most') {
      setOptions(question.answerOptions
      .filter(({ option }) => !selectedBest || (option !== selectedBest?.id && option !== selectedBest?.option))
      .map((answerOption) => ({
        id: answerOption.option,
        label: answerOption.text,
        hints: answerOption.description,
      })));
    } else {
      // multiple target question
      setOptions(question.answerOptions
      .filter(({ option }) => !selectedBest || (option !== selectedBest?.id && option !== selectedBest?.option))
      .filter(({ option }) => !selectedLeast || (option !== selectedLeast?.id && option !== selectedLeast?.option))
      .map((answerOption) => ({
        id: answerOption.option,
        label: answerOption.text,
        hints: answerOption.description,
      })));
    }
  }, [
    best,
    least,
    options.length,
    question,
    selectedValue,
  ]);

  useEffect(() => {
    if (!selectedValue) {
      return;
    }

    setBest(selectedValue.best);
    setLeast(selectedValue.least);
  }, [ selectedValue ]);

  // Update answer on state, but do not send it yet
  useEffect(() => {
    if (!best || (!least && !singleTarget)) {
      return;
    }

    // for single target questions the one that is not selected should be set as least.
    // effect will trigger again after setLeast edit
    if (singleTarget && least?.id !== options[0]?.id) {
      setLeast(options[0]);
      return;
    }

    const ids = question.answerOptions.map(({ option }) => option);
    if ((selectedValue?.best?.id !== best?.id || selectedValue?.least?.id !== least?.id)
      && (ids.includes(best?.id) && ids.includes(least?.id))
    ) {
      dispatchLocal({ type: 'updateAnswer', payload: { best, least } });
    }
  }, [ best, least, dispatchLocal, selectedValue, options, singleTarget, question.answerOptions ]);

  const handleDragEnd = (result) => {
    const { draggableId, source: from, destination: to } = result;
    const index = draggableId.split('-')[0];

    if (!to?.droppableId) {
      return;
    }

    if (to.droppableId === 'options') {
      const newOptions = [ ...options ];
      switch (from.droppableId) {
        case 'best': {
          setBest();
          setOptions([ best, ...newOptions ]);
          break;
        }
        case 'least': {
          setLeast();
          setOptions([ least, ...newOptions ]);
          break;
        }
        case 'options': {
          const item = options[index];
          newOptions.splice(index, 1);
          newOptions.splice(to.index, 0, item);
          setOptions(newOptions);
          break;
        }
        default: {
          setOptions(newOptions);
        }
      }
    } else {
      if (to.droppableId === 'best' && from.droppableId === 'least') {
        setBest(least);
        setLeast();
        if (best) setLeast(best);

        return;
      }

      if (to.droppableId === 'best' && from.droppableId === 'options') {
        const item = options[index];
        let newOptions = [ ...options ];
        newOptions.splice(index, 1);
        if (best) newOptions = [ best, ...newOptions ];
        setBest(item);
        setOptions(newOptions);

        return;
      }

      if (to.droppableId === 'least' && from.droppableId === 'best') {
        setLeast(best);
        setBest();
        if (least) setBest(least);

        return;
      }

      if (to.droppableId === 'least' && from.droppableId === 'options') {
        const item = options[index];
        let newOptions = [ ...options ];
        newOptions.splice(index, 1);
        if (least) newOptions = [ least, ...newOptions ];
        setLeast(item);
        setOptions(newOptions);
      }
    }
  };

  // MODAL
  const [ helpModalVisible, setHelpModalVisible ] = useState();

  // RENDER: HINT
  const renderHint = () => {
    const hint = [];

    const translation = translate('assessment_bipolar_slider__hint') || DUMMY_HINT;

    if (translation) {
      translation
      .split('{{key arrow up / key arrow down}}')
      .forEach((item, index) => {
        if (index === 0) {
          hint.push(
            <div className={styles.hintFlex} key={index}>
              <span>{ item }</span>
              <CursorUp />
              <CursorDown />
            </div>,
          );
        } else {
          hint.push(<span key={index}>{ item }</span>);
        }
      });
    }

    return hint;
  };

  // RENDER: DRAGGABLE COMPONENT
  const renderDraggableComp = (isDragging, draggableComp) => {
    if (isDragging) {
      // portal is used here to fix an offset issue on dragging
      return createPortal(draggableComp, document.body);
    }
    return draggableComp;
  };


  if (!question) {
    return null;
  }

  // RENDER: QuestionDragDropBestLeast
  return (
    <div
      className={classNames(styles.questionDragDropBestLeast)}
      data-test='Question'
      data-qt='select-most-least'
      data-id={question.id}
    >

      { /* CONTAINER */ }
      <div className={styles.container}>
        <div className={styles.questionHeader}>
          <div className={styles.questionTitle}>
            { singleTarget
              ? translate('disc_ass_single_target_question')
              : translate('disc_ass_multiple_targets_question') }
          </div>

          { question.description && (
            <div className={styles.questionDescription}>
              { translate(question.description) }
            </div>
          ) }
        </div>

        { /* DRAG & DROP */ }
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className={styles.dragDrop}>

            { /* LEFT */ }
            <div className={styles.left}>
              <div className={styles.title}>{ translate('characteristics') }</div>

              { /* OPTIONS */ }
              <Droppable droppableId='options'>
                { (provided, snapshot) => (
                  <div
                    className={styles.options}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    { options.map((item, index) => (
                      <Draggable key={index} draggableId={`${index}-draggable`} index={index}>
                        { (provided, snapshot) => {
                          const draggableComp = (
                            <div
                              className={classNames(styles.option, { [styles.isDragging]: snapshot.isDragging })}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div>
                                <div className={styles.optionLabel}>{ item.label }</div>
                                <div className={styles.optionHints}>
                                  { item.hints }
                                </div>
                              </div>
                            </div>
                          );

                          return renderDraggableComp(snapshot.isDragging, draggableComp);
                        } }
                      </Draggable>
                    )) }

                  </div>
                ) }
              </Droppable>
            </div>

            { /* RIGHT */ }
            <div className={styles.right}>
              { /* BEST */ }
              <div className={styles.title}>{ translate('applies_best_lbl') }</div>
              <Droppable droppableId='best'>
                { (provided, snapshot) => (
                  <div
                    className={styles.options}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    { (!best || snapshot.draggingFromThisWith) && (
                      <div className={classNames(
                        styles.option,
                        styles.empty,
                        { [styles.draggedOver]: snapshot.isDraggingOver },
                      )}
                      >
                        { translate('drag_here_lbl') }

                      </div>
                    ) }

                    { best && (
                      <Draggable draggableId='draggable-best' index={1}>
                        { (provided, snapshot = {}) => {
                          const draggableComp = (
                            <div
                              className={classNames(styles.option, styles.dragged, { [styles.isDragging]: snapshot.isDragging })}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div>
                                <div className={styles.optionLabel}>{ best.label }</div>
                                <div className={styles.optionHints}>
                                  { best.hints }
                                </div>

                                <div
                                  className={styles.closeIcon}
                                  onClick={() => {
                                    handleDragEnd({
                                      draggableId: 'draggable-best',
                                      source: { droppableId: 'best' },
                                      destination: { droppableId: 'options' },
                                    });
                                  }}
                                >
                                  <Close />
                                </div>
                              </div>
                            </div>
                          );

                          return renderDraggableComp(snapshot.isDragging, draggableComp);
                        } }
                      </Draggable>
                    ) }
                  </div>
                ) }
              </Droppable>

              { !singleTarget && (
                <>
                  <div className={styles.titleWithMargin}>{ translate('applies_least_lbl') }</div>
                  <Droppable droppableId='least'>
                    { (provided, snapshot) => (
                      <div
                        className={styles.options}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        { (!least || snapshot.draggingFromThisWith) && (
                          <div className={classNames(
                            styles.option,
                            styles.empty,
                            { [styles.draggedOver]: snapshot.isDraggingOver },
                          )}
                          >
                            { translate('drag_here_lbl') }

                          </div>
                        ) }

                        { least && (
                          <Draggable draggableId='draggable-least' index={1}>
                            { (provided, snapshot) => {
                              const draggableComp = (
                                <div
                                  className={classNames(styles.option, styles.dragged, { [styles.isDragging]: snapshot.isDragging })}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>
                                    <div className={styles.optionLabel}>{ least.label }</div>
                                    <div className={styles.optionHints}>
                                      { least.hints }
                                    </div>

                                    <div
                                      className={styles.closeIcon}
                                      onClick={() => {
                                        handleDragEnd({
                                          draggableId: 'draggable-least',
                                          source: { droppableId: 'least' },
                                          destination: { droppableId: 'options' },
                                        });
                                      }}
                                    >
                                      <Close />
                                    </div>
                                  </div>
                                </div>
                              );

                              return renderDraggableComp(snapshot.isDragging, draggableComp);
                            } }
                          </Draggable>
                        ) }
                      </div>
                    ) }
                  </Droppable>
                </>
              ) }
              { /* LEAST */ }
            </div>

          </div>
        </DragDropContext>

        <Button
          disabled={!best || (!singleTarget && !least)}
          onClick={() => onAnswer({ best, least })}
        >
          { translate('continue_lbl') }
        </Button>

        { !question.hideHelp && (
          <Button
            size='S'
            looks='tertiary'
            onClick={() => setHelpModalVisible(true)}
          >
            { translate('assessment_help_button') }
          </Button>
        ) }

        <div className={classNames('bluTypeHint', 'marginTopM')}>
          { singleTarget
            ? translate('disc_ass_single_target_hint')
            : translate('disc_ass_multiple_targets_hint') }
        </div>

        <div className={classNames(styles.hintFlex, 'bluTypeHint', 'marginTopXxs')}>
          { renderHint() }
        </div>
      </div>

      { helpModalVisible && (
        <Modal
          header={translate('assessment_help_info_title')}
          onClose={() => setHelpModalVisible(false)}
          secondaryButtonTitle={translate('close_lbl')}
        >
          { translate('disc_ass_help_modal_copy') }

          { singleTarget
            ? <SingleTargetGraphic className={styles.helpMeGraphic} />
            : <MultipleTargetsGraphic className={styles.helpMeGraphic} /> }
        </Modal>
      ) }

    </div>
  );
};

export default QuestionDragDropBestLeast;
