import React from 'react';
import styles from './AssessmentGuidance.module.scss';

import classNames from 'classnames';

// OTHER COMPONENTS
import { Callout } from 'ui/basic';
import { AssessmentPageWithFooter } from '../AssessmentPageWithFooter';

// UTILS
import { useTranslate } from 'utils/translator';
import { markdown } from 'utils/textTools';

import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { ASSESSMENT_NEXT_TYPES } from '../../AssessmentNext.config';
import { getTranslationIds } from '../../AssessmentNext.translations';

// DATA
import { DEFAULT_COPYRIGHT_TITLE, DEFAULT_COPYRIGHT_DESCRIPTION } from './AssessmentGuidance.translations';


const AssessmentGuidance = (props) => {
  const {
    assessmentType = ASSESSMENT_TYPES.BIG5,
    content = null,
    showCopyright,
    onClickNext,
  } = props;

  const translate = useTranslate();

  const assessmentNextName = ASSESSMENT_NEXT_TYPES[assessmentType] || assessmentType;
  const translationIds = getTranslationIds(assessmentNextName);


  return (
    <AssessmentPageWithFooter
      copyrightShort={showCopyright && (translate(translationIds.copyright, [ '{{year}}', new Date().getFullYear() ])
        || DEFAULT_COPYRIGHT_TITLE)}
      copyrightDescriptionHeader={translate(translationIds.copyrightInfoTitle) || DEFAULT_COPYRIGHT_TITLE}
      copyrightDescriptionContent={translate(translationIds.copyrightInfoDescription) || DEFAULT_COPYRIGHT_DESCRIPTION}
      nextButtonText={translate('assessment_start')}
      onClickNext={onClickNext}
      dataTest='Question'
    >

      <div className={classNames(styles.assessmentGuidance)}>
        <Callout
          triangleOnTop={false}
          triangleOnBottom
          trianglePosition='left'
        >
          <div className={styles.calloutContent}>
            { translate('assessment_guidance_callout') }
          </div>
        </Callout>

        <div className={styles.description}>
          { content === null
            ? translate(translationIds.guidanceDescription)
            : markdown(content) }
        </div>
      </div>
    </AssessmentPageWithFooter>
  );
};

export default AssessmentGuidance;
